import React, { useState } from 'react';
import './Resume.css'; // Make sure you have the Resume.css file

const Resume: React.FC = () => {
    const [language, setLanguage] = useState('english'); // 'english' or 'german'

    const toggleLanguage = () => {
        setLanguage(prevLang => (prevLang === 'english' ? 'german' : 'english'));
    };

    const getResumePdfPath = () => {
        return language === 'english' ? '/path-to-english-resume.pdf' : '/path-to-german-resume.pdf';
    };

    return (
        <div className="resume-page">
            <div className="language-switcher">
                <button onClick={toggleLanguage}>
                    {language === 'english' ? 'Deutsch' : 'English'}
                </button>
            </div>
            <div className="resume-content">
                {/* Render your resume content based on the selected language */}
                {language === 'english' ? <EnglishResume /> : <GermanResume />}
            </div>
            <div className="download-resume">
                <a href={getResumePdfPath()} download>
                    <img src="/icons/download.svg" alt="Download" />
                </a>
            </div>
        </div>
    );
};

// Placeholder components for English and German resumes
// Replace with your actual resume content
const EnglishResume = () => {
    return <div>English Resume Content Here</div>;
};

const GermanResume = () => {
    return <div>German Resume Content Here</div>;
};

export default Resume;

