// Blog.tsx (React Component)
import React, { useState } from 'react';
import './Blog.css';

const BlogEntry = ({ title, subtitle, content }: { title: string, subtitle: string, content: string }) => {
    const [isExpanded, setIsExpanded] = useState(false);

    const toggleExpand = () => {
        setIsExpanded(!isExpanded);
    };

    return (
        <div className={`blog-entry ${isExpanded ? 'expanded' : ''}`} onClick={toggleExpand}>
            <h2>{title}</h2>
            <h3>{subtitle}</h3>
            <p>{content}</p>
        </div>
    );
};

const Blog = () => {
    // Assume you have a list of blog entries
    const blogEntries = [
        {
            "id": 1, "title": "",
            "subtitle": "",
            "content": "",
        },
        {
            "id": 2, "title": "",
            "subtitle": "",
            "content": ""
        },
    ];

    return (
        <div className="blog-page">
            <h1> Hello BlogPage </h1>
            {blogEntries.map(entry => (
                <BlogEntry key={entry.id} title={entry.title} subtitle={entry.subtitle} content={entry.content} />
            ))}
        </div>
    );
};

export default Blog;

