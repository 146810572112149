import React, { useState } from 'react';
import './Projects.css'; // Make sure you have the CSS file

// Define the project type
type Project = {
    id: number;
    name: string;
    description: string;
    images: string[]; // URLs to the images
    technologies: string[]; // URLs or names of the technologies used
};

// Mock data for projects
const projects: Project[] = [
    // ... populate with your project data
    {
        id: 1,
        name: 'Client Management App',
        description: 'A robust client management application built with Java, leveraging the JavaFX ' +
            'framework for the user interface and Spring Boot for the backend services. ' +
            'With persitent storage in a MySQL database, this application enables users to manage ' +
            'their client information effectively, ' +
            'with features for adding, deleting and updating client details, as well as ' +
            'reporting and analytics tools.',
        images: [
            // ... more images
            '/images/cm1.png',
            '/images/cm2.png',
            '/images/cm3.png',
            '/images/cm4.png',
            '/images/cm5.png',
            '/images/cm6.png',
        ],
        technologies: [
            '/icons/java.svg', // Replace with paths to your images
            '/icons/mysql.svg',
            '/icons/springboot.svg',
        ],
    },
    {
        id: 2,
        name: 'Personal Website',
        description: 'This website was built with React and TypeScript. ' +
            'It is hosted on Google Cloud Platform, using Google Cloud Storage and Google Cloud Run. ' +
            'The website is built with a responsive design, so it looks good on all devices. ',
        images: [
            // ... more images
            '/images/www1.png',
            '/images/www2.png',
        ],
        technologies: [
            '/icons/react.svg', // Replace with paths to your images
            '/icons/typescript.svg',
            '/icons/html.svg',
            '/icons/css.svg',
            '/icons/googlecloud.svg',
        ],
    },
    // ... more projects
];


const Projects: React.FC = () => {
    return (
        <section id="projects">
            {projects.map((project) => (
                <div key={project.id} className="project" style={{ width: '80%', margin: '0 auto', marginBottom: '50px' }}>
                    <h3>{project.name}</h3>
                    <ProjectGallery project={project} />
                    <p>{project.description}</p>
                    <div className="technologies">
                        {project.technologies.map((tech, index) => (
                            <img key={index} src={tech} alt={`Technology ${index}`} className="technology-icon" />
                        ))}
                    </div>
                </div>
            ))}
        </section>
    );
};

const ProjectGallery: React.FC<{ project: Project }> = ({ project }) => {
    const [currentImageIndex, setCurrentImageIndex] = useState(0);

    const nextImage = () => {
        setCurrentImageIndex((prevIndex) =>
            prevIndex === project.images.length - 1 ? 0 : prevIndex + 1
        );
    };

    const prevImage = () => {
        setCurrentImageIndex((prevIndex) =>
            prevIndex === 0 ? project.images.length - 1 : prevIndex - 1
        );
    };

    return (
        <div className="image-gallery">
            <button className="button left" onClick={nextImage}>{"<"}</button>
            <img src={project.images[currentImageIndex]} alt={project.name} />
            <button className="button right" onClick={prevImage}>{">"}</button>
        </div >
    );
};

export default Projects;
