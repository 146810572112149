import React from 'react';
import './LandingPage.css';
import Projects from './Projects';

const LandingPage: React.FC = () => {
    return (
        <div className="landing-page">
            <div className="intro">
                <p>Hi, my name is</p>
                <h1>Tim Wenzel.</h1>
                <h2>Welcome to my page!</h2>
                <p>
                    I'm a computer science student specializing in artificial intelligence.
                    I'm passionate about creating software that solves real-world problems and
                    improves people's lives.
                    I'm currently working as a Java Developer @ <a href="https://sq-solutions.de/" target="_blank" rel="noopener noreferrer">SQ-Solutions</a>.
                </p>
            </div>
            <div className="social-links">
                <a href="https://github.com/timwenzeltech" target="_blank" rel="noopener noreferrer">
                    <img src="/icons/github.svg" alt="Github" />
                </a>
                <a href="https://www.linkedin.com/in/tim-wenzel-44a13a225/" target="_blank" rel="noopener noreferrer">
                    <img src="/icons/linkedin.svg" alt="LinkedIn" />
                </a>
                <a href="mailto:tim.wenzel@outlook.de" target="_blank" rel="noopener noreferrer">
                    <img src="/icons/mail.svg" alt="Mail" />
                </a>
            </div>
            <div className="portfolio-link" style={{ marginTop: '150px' }}>
                <h2>Check out my portfolio</h2>
                <span className="arrow">{"↓"}</span>
            </div>
            <Projects />
        </div>
    );
};

export default LandingPage;
