import React from 'react';
import './Header.css'; // Assume you have styles defined here
import { useNavigate } from 'react-router-dom'
const Header: React.FC = () => {
    const navigate = useNavigate();

    const handlePortfolioClick = () => {
        if (window.location.pathname !== '/') {
            navigate('/'); // Navigate to home page
            // Optionally, you can add a slight delay before scrolling
            setTimeout(() => {
                const projectsElement = document.getElementById('projects');
                if (projectsElement) {
                    projectsElement.scrollIntoView({ behavior: 'smooth' });
                }
            }, 100); // Adjust delay as needed
        } else {
            const projectsElement = document.getElementById('projects');
            if (projectsElement) {
                projectsElement.scrollIntoView({ behavior: 'smooth' });
            }
        }
    };
    return (
        <header className="site-header">
            <div className="logo">
                {/* Your logo here */}
                TW
            </div>
            <nav className="main-nav">
                <ul>
                    <li><a href="/">Home</a></li>
                    <li><a href="#projects" onClick={handlePortfolioClick}>Portfolio</a></li>
                    <li><a href="/services">Services</a></li>
                    <li><a href="/blog">Blog</a></li>
                    <li><a href="/resume">Resume</a></li>
                </ul>
            </nav>
        </header>
    );
};

export default Header;

